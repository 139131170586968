/* da6c9e - pink,  */

.App {
  text-align: center;
}

.header-container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

a {
  text-decoration: none;
  color: black;
}

.App-logo {
  height: 18vmin;
  pointer-events: none;
  margin-top: 20px;
}

.App-header {
  background-color: #ffff;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-family: "Amatic SC", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.main-section-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
}

.main-section-row {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
}

.main-section-row h2 {
  margin: 10px;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
}

.section-content-txt {
  font-size: 18px !important;
  padding: 20px;
  background-color:  #00FFFF10;

}

.main-section-row h2:hover {
  background-color:  #00FFFF40;
  text-decoration: underline#50195e;
  cursor: pointer;
  border-radius: 4px;
}

.main-section-row-content {
  min-height: 200px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
}



.main-section-row-content a {
  padding: 8px;
 width: fit-content;
  text-decoration: underline #50195e;
}


.main-section-row-content a:hover {
  background-color:  #00FFFF30;
  cursor: pointer;
  border-radius: 4px;
 
}

.bold {
  font-weight: 800;
}

footer p {
  bottom: 0;
  margin-top: 100px;
  font-size: 14px;
}

.highlight-section {
  background-color:  #00FFFF30;
  text-decoration: underline #50195e;
  cursor: pointer;
  border-radius: 4px;
}

.reg-section {
  border: none;
}



.page-container {
      width: 80%;
      display: flex;
      flex-direction: column;
     text-align:justify;
    font-family:Arial, Helvetica, sans-serif;
}

.section-content p, li {

     font-family:Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: gray;
    margin-left: 0  !important;
    padding-left: 0  !important;
}

.page-header {
    font-family:Arial, Helvetica, sans-serif;
    font-size: 32px !important;
    font-weight: 400 !important;
    color: gray;
    margin-left: 0  !important;
    padding-left: 0  !important;
}

.page-section-container {
    width: 100%;
    display: flex;
    flex-direction: row;
        justify-content: start;
}


.section-content {
    margin-left: 48px;
 
    text-align: start;
  display: flex;
flex-direction: column;
align-items: start;
justify-content: start;
}



.page-section-item-container {
    display: flex;
    align-items:  start;
    justify-content: start;
    width: 100%;
    flex-direction: row;
    margin-bottom: 64px;
    margin-top: 64px;
}

.section-header {
    margin-top: 32px !important;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 28px !important;
    font-weight: 400 !important;
    color: gray;
    margin-left: 0  !important;
    padding-left: 0  !important;
    text-align: start;
}



ul {
    margin-left: 0;
    padding-left: 0;
    width: 80%;
}

li {

    text-align: start;
    justify-content: start;
    margin-top: 20px;
    list-style-type: disc;

}

.section-icons {
    margin-top: 32px;
    margin-right: 20px;
}





@media only screen and (max-width: 600px) {
  .page-section-item-container {
    flex-direction: column;
  }

  .header-container {
    flex-direction: row-reverse;
  }

  .header-container img {
    display: none;
  }
}

@media only screen and (min-width: 601px) {
  .header-container-sm-screen {
    display: none;
  }
}

.bullet-line-top {
  width: 80%;
  height: 6px;
  margin: 0 !important;
  padding: 0 !important;
    opacity: 40%;
      
}


.bullet-line-bottom {
  width: 60%;
  height: 4px;
  margin-left: 0;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 20%;
  background-color: darkred;
}

